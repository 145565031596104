/**
 * Combined by jsDelivr.
 * Original files:
 * - /gh/highlightjs/cdn-release@9.13.0/build/languages/dockerfile.min.js
 * - /gh/highlightjs/cdn-release@9.13.0/build/languages/go.min.js
 * - /gh/highlightjs/cdn-release@9.13.0/build/languages/gradle.min.js
 * - /gh/highlightjs/cdn-release@9.13.0/build/languages/json.min.js
 * - /gh/highlightjs/cdn-release@9.13.0/build/languages/less.min.js
 * - /gh/highlightjs/cdn-release@9.13.0/build/languages/plaintext.min.js
 * - /gh/highlightjs/cdn-release@9.13.0/build/languages/scss.min.js
 * - /gh/highlightjs/cdn-release@9.13.0/build/languages/typescript.min.js
 * - /gh/highlightjs/cdn-release@9.13.0/build/languages/yaml.min.js
 * - /npm/highlightjs-line-numbers.js@2.6.0/dist/highlightjs-line-numbers.min.js
 *
 * Do NOT use SRI with dynamically generated files! More information: https://www.jsdelivr.com/using-sri-with-dynamic-files
 */
